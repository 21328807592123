.admin-container {
    padding: 20px;
}

.logout-container {
    display: flex;
    justify-content: flex-end;
}

.total-refresh-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.refresh-button {
    margin-left: 20px;
}