.waitlist-main-container {
  width: 100%;
  /* height: 180vh; */
  background-color: black;
  background-image: url("../../Images/mobile-moon.png");
  background-position: top;
  background-position: bottom;
  background-repeat: no-repeat;
}

.waitlist-logo-buttons-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.waitlist-contact-us {
  display: none;
}

.waitlist-contact-follow-button {
  display: flex;
  align-items: center;
}

.waitlist-listing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 20px;
}

.waitlist-follow-us-btn {
  text-decoration: none;
}

.waitlist-heading {
  text-align: center;
  text-shadow: 0px 25px 50px rgba(0, 0, 0, 0.25);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  background: linear-gradient(93deg, #FAFAFA 24.45%, #A1A1AA 73.78%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 16px;
}

.waitlist-subheading {
  color: var(--muted-foreground, #A1A1AA);
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  height: 56px;
  align-self: stretch;
}

.waitlist-form-container {
  padding: 20px 20px 160px;
  margin-top: 20px;
}

.waitlist-form-single-container {
  margin-bottom: 20px;
}

.waitlist-form-label {
  color: white;
  font-size: 12px;
  margin-bottom: 10px !important;
}

.waitlist-form-container .ant-input-outlined:hover {
  border-color: none;
  background-color: #1d1d1f;
  border-color: #1d1d1f;
}

.waitlist-form-container .ant-input-affix-wrapper .ant-input-prefix {
  color: white;
}

.waitlist-form-container .ant-input-affix-wrapper-lg {
  background: #1d1d1f;
  border-color: #1d1d1f;
}

.waitlist-form-container .ant-input-outlined:focus-within {
  box-shadow: none
}

.waitlist-form-container input.ant-input.ant-input-lg.css-dev-only-do-not-override-5wsri9 {
  color: white !important;
  padding-left: 5px;
}

.ant-input::placeholder {
  color: #727272;
}

.waitlist-form-container .ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: white;
}

.waitlist-form-container :where(.css-5wsri9).ant-input-affix-wrapper >input.ant-input, :where(.css-5wsri9).ant-input-affix-wrapper >textarea.ant-input {
  color: white;
}

.waitlist-form-container :where(.css-5wsri9).ant-input-affix-wrapper >input.ant-input {
  color: white !important;
}

.waitlist-join-button {
  color: white;
  background-color: #811fd3;
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  border-color: #811fd3;
}

.waitlist-text-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  height: 17%;
}

.waitlist-list-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 11.1%;
  max-width: 100%;
  width: 310px;
  margin: 0 auto;
}

.waitlist-contact-follow-button .ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover {
  color: white;
  border-color: #811fd3;
  background: #811fd3;
}

.waitlist-list-container .ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover {
  color: white !important;
  border-color: #811fd3 !important;
  background: #811fd3 !important;
}

.ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover, :where(.css-dev-only-do-not-override-5wsri9).ant-btn-variant-dashed:not(:disabled):not(.ant-btn-disabled):hover {
  color: white !important;
  border-color: #811fd3 !important;
  background: #811fd3 !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
  transition: background-color 5000s ease-in-out 0s;
}


@media screen and (min-width: 375px) {
  .waitlist-list-container {
      height: 15.1%;
  }
}

@media screen and (min-width: 768px) {
  .waitlist-listing-main-container {
    max-width: 450px;
    margin: 0 auto;
  }
  /* .waitlist-listing-container {
      padding: 70px 190px 0px;
  } */
  /* .waitlist-form-container {
      padding: 20px 190px;
  } */
  .waitlist-contact-us {
      display: block;
      margin-right: 20px;
      background-color: transparent;
      color: white;
  }
  .waitlist-list-container {
      height: 12.1%;
  }
}

@media screen and (min-width: 1024px) {
  .waitlist-main-container {
      width: 100%;
      height: 100vh;
      background-image: url("../../Images/Final-Waitlist-Page.png");
      background-position: top;
      background-position: bottom;
      background-repeat: no-repeat;
  }
  .waitlist-logo-buttons-container {
      padding: 30px 40px;
  }
  .waitlist-form-desktop-container {
      display: flex;
      justify-content: space-between;
  }
  .waitlist-listing-main-container {
    max-width: 570px;
    margin: 0 auto;
}
  /* .waitlist-listing-container {
      padding: 0px 280px 0px;
      margin: 0px;
  } */
  .waitlist-form-container {
      padding: 20px 20px 0px;
      margin: 0px;
  }
  .waitlist-list-container {
      display: none;
  }
}

@media screen and (min-width: 1200px) {
  /* .waitlist-listing-container {
      padding: 0px 350px 0px;
      margin: 0px;
  } */
  /* .waitlist-form-container {
      padding: 20px 420px;
  } */
  .waitlist-logo-buttons-container {
      padding: 30px 40px 10px;
  }
  .waitlist-form-container {
      margin-top: 0px;
  }
}