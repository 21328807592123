.ant-collapse>.ant-collapse-item >.ant-collapse-header {
    color: white;
}

.ant-collapse .ant-collapse-content {
    background-color: black;
    border-top: none;
}

.ant-collapse {
    border: none;
}

.ant-collapse>.ant-collapse-item {
    border-bottom: 1px solid #5d5a5a;
}

