.thank-you-main-container {
    background-color: black;
    height: auto;
}

.thank-you-logo-container {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.thank-you-follow-us-btn {
    text-decoration: none;
    display: flex;
    align-items: center;
}

.thank-you-first-container {
    width: 100%;
    position: relative;
    padding: 30px 20px 60px;
}

.thank-you-onboarding-container {
    display: flex;
}

.thank-you-onboard-message {
    text-align: center;
    text-shadow: 0px 25px 50px rgba(0, 0, 0, 0.25);
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    background: linear-gradient(90deg, #7816C9 0%, #FFF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.thank-you-glow-one {
    position: absolute;
    bottom: 20px;
    width: 90%;
}

.no-style {
    all: initial;
}

.thank-you-subtitle {
    text-align: center;
    text-shadow: 0px 25px 50px rgba(0, 0, 0, 0.25);
    font-family: Inter;
    font-size: 29px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    background: linear-gradient(93deg, #FAFAFA 24.45%, #A1A1AA 73.78%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 30px;
}


.thank-you-description {
    text-align: center;
    color: var(--muted-foreground, #A1A1AA);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin-top: 10px;
}

.thank-you-second-container {
    width: 100%;
    padding: 20px;
}

.thank-you-why-choose-text {
    text-shadow: 0px 25px 50px rgba(0, 0, 0, 0.25);
    font-family: Inter;
    font-size: 29px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    background: linear-gradient(93deg, #FAFAFA 24.45%, #A1A1AA 73.78%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(93deg, #FAFAFA 24.45%, #A1A1AA 73.78%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

.thank-you-why-choose-box {
    display: flex;
    padding: var(--Spacing-6, 24px);
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--Border-Radius-rounded-xl, 12px);
    border: 2px solid var(--border, rgba(255, 255, 255, 0.20));
    background: var(--card, rgba(250, 250, 250, 0.02));
    box-shadow: 0px 0px var(--Spacing-16, 64px) 0px var(--foreground-foreground-5, rgba(9, 9, 11, 0.05)) inset;
    margin-top: 20px;
}


.thank-you-why-choose-title {
    color: var(--card-foreground, #FAFAFA);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.thank-you-why-choose-description {
    color: var(--muted-foreground, #A1A1AA);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    height: 120px;
    margin-top: 10px;
}

.thank-you-why-choose-img {
    width: 100%;
    margin-top: 30px;
}

.thank-you-third-container {
    padding: 20px;
    margin-top: 20px;
}

.thank-you-early-access-text {
    text-align: left;
    text-shadow: 0px 25px 50px rgba(0, 0, 0, 0.25);
    font-family: Inter;
    font-size: 29px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    align-self: stretch;
    background: linear-gradient(93deg, #FAFAFA 24.45%, #A1A1AA 73.78%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.thank-you-dont-miss-box {
    display: flex;
    height: 276px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--Border-Radius-rounded-xl, 12px);
    border: 1.5px solid #AF57DB;
    background: var(--accent, rgba(250, 250, 250, 0.10));
    box-shadow: 0px 0px var(--Spacing-16, 64px) 0px var(--foreground-foreground-5, rgba(9, 9, 11, 0.05)) inset;
    margin-top: 20px;
}

.thank-you-dont-miss-box-title  {
    align-self: stretch;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    background: linear-gradient(90deg, #FFF 0%, #999 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 20px 0px 10px;
}

.thank-you-dont-miss-box-description {
    align-self: stretch;
    color: var(--muted-foreground, #A1A1AA);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.thank-you-fourth-container {
    width: 100%;
    padding: 20px;
    margin-top: 20px;
}

.thank-you-frequently-title {
    text-align: left;
    text-shadow: 0px 25px 50px rgba(0, 0, 0, 0.25);
    font-family: Inter;
    font-size: 29px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    background: linear-gradient(93deg, #FAFAFA 24.45%, #A1A1AA 73.78%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.thank-you-frequently-accordian-container {
    margin-top: 20px;
}

.thank-you-frequently-accordian-question {
    color: var(--card-foreground, #FAFAFA);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.thank-you-frequently-accordian-answer {
    align-self: stretch;
    color: var(--muted-foreground, #A1A1AA);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    background-color: black;
}

.thank-you-fifth-container {
    width: 100%;
    height: 250px;
    padding: 20px 20px 0px;
    margin-top: 20px;
    position: relative;
}

.thank-you-follow-title {
    text-align: left;
    text-shadow: 0px 25px 50px rgba(0, 0, 0, 0.25);
    font-family: Inter;
    font-size: 29px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    background: linear-gradient(93deg, #FAFAFA 24.45%, #A1A1AA 73.78%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.thank-you-follow-links {
    display: flex;
    justify-content: space-around;
    width: 210px;
    margin: 40px auto;
    align-items: baseline;
}

.thank-you-glow-two {
    position: absolute;
    bottom: 0px;
    width: 90%;
}

.follow-us-btns {
    border: none;
    background: transparent;
    z-index: 9999;
}

.follow-us-images {
    width: 44px;
    height: 44px;
}

@media screen and (min-width: 768px) {
    .thank-you-follow-us-btn {
        display: block;
        text-decoration: none;
    }
    .thank-you-why-choose-container {
        display: flex;
        justify-content: space-between;
    }
    .thank-you-why-choose-box {
        width: 49%;
    }
    .thank-you-early-access-text {
        text-align: center;
        font-size: 42px;
    }
    .thank-you-dont-miss-box-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 20px;
    }
    .thank-you-dont-miss-box {
        display: flex;
        width: 235px;
        height: 300px;
        min-width: 235px;
    }
    .thank-you-frequently-title {
        text-align: center;
        font-size: 42px;
    }
    .thank-you-follow-title {
        text-align: center;
        font-size: 42px;
    }
}

@media screen and (min-width: 1024px) {
    .thank-you-logo-container {
        max-width: 800px;
        margin: 0px auto;
    }
    .thank-you-first-container {
        max-width: 800px;
        margin: 0px auto;
    }
    .thank-you-second-container {
        max-width: 800px;
        margin: 20px auto;
    }
    .thank-you-third-container {
        max-width: 800px;
        margin: 100px auto;
    }
    .thank-you-fourth-container {
        max-width: 800px;
        margin: 100px auto;
    }
    .thank-you-fifth-container {
        max-width: 800px;
        margin: 20px auto 0px;
    }
    .thank-you-onboard-message {
        font-size: 28px;
    }
    .thank-you-subtitle {
        font-size: 52px;
    }   
    .thank-you-description {
        font-size: 18px;
        text-align: center;
    }
    .thank-you-why-choose-text {
        font-size: 42px;
    }
    .thank-you-why-choose-box {
        display: flex;
    }
    .thank-you-why-choose-description {
        height: 100px;
    }
}

@media screen and (min-width: 1440px) {
    .thank-you-logo-container {
        max-width: 900px;
        margin: 0px auto 20px;
    }
    .thank-you-first-container {
        max-width: 900px;
        margin: 20px auto;
    }
    .thank-you-second-container {
        max-width: 900px;
        margin: 20px auto;
    }
    .thank-you-third-container {
        max-width: 900px;
        margin: 100px auto;
    }
    .thank-you-fourth-container {
        max-width: 900px;
        margin: 100px auto;
    }
    .thank-you-fifth-container {
        max-width: 900px;
        margin: 20px auto 0px;
    }
    .thank-you-dont-miss-box {
        display: flex;
        width: 270px;
        height: 300px;
        min-width: 235px;
    }
}